<template>
  <div>
    <div class="w-full flex flex-col gap-3 lg:gap-6">
      <div class="w-full flex flex-row">
        <Loader v-show="profileLoading || picturesLoadingCounter > 0 || gettingAnotherProfile"
          class="w-full mx-auto lg:h-sidebar"
          :class="getHeightOfScreenWidth(1,48)"
          no-border
        />
        <div  class="w-full flex flex-col" v-show="!profileLoading && picturesLoadingCounter <= 0 && !gettingAnotherProfile">
          <!-- Przyciski nad galerią -->
          <div v-if="!profileData.removedAt && !$route.name.includes('chats') && !profileAdministrationData.isAdmin"
            class="flex flex-row w-full items-center gap-3 lg:gap-6 mb-3 lg:mb-6"
            :class="{
              'justify-between':!profileData.threadId,
              'justify-end':profileData.threadId,
            }"
          >
            <div v-if="!profileData.threadId && !profileData.userRememberedBy"
              class="flex flex-row w-full gap-6 items-center justify-between"
            >
              <!-- Skip button -->
              <ButtonSkip @clicked="skipProfile" :loading="profileSkipping"/>
              <!-- Profiles counter -->
              <ProfilesCounter v-show="!isMobile" :message="profilesToShowMessage"/>
            </div>
            <!-- Placeholder to keep layout-->
            <div v-else/>

            <div class="flex flex-row w-full lg:w-min gap-6 items-center justify-end">
              <!-- Forget button -->
              <Button
                v-show="profileData.userRememberedBy"
                variant="textWithIcon"
                class="text-s"
                :icon="'star'"
                :icon-height="16" :icon-width="16"
                :loading="profileRememberedLoading"
                :text="trans('BUTTON:Forget')"
                @clicked="forgetUserAction(profileData.username)"
              />
              <!-- Remember button -->
              <Button
                v-show="!profileData.threadId && !profileData.userRememberedBy"
                variant="textWithIcon"
                class="text-s"
                :icon="'starFilled'"
                :icon-height="16" :icon-width="16"
                :loading="profileRememberedLoading"
                :text="trans('BUTTON:RememberUser')"
                @clicked="
                  profileData.isBanned && !profileData.userRememberedBy? openAccountBlockedRememberForbiddenModal(true)
                    :profileData.isFake? openModalFailedAction(true,'remember')
                        :rememberUserAction(profileData.username)"
              />

              <div v-if="$route.name !== 'chats'">
                <!-- Invite or Chat button -->
                <Button
                  v-show="profileData.threadId"
                  variant="buttonRed"
                  @clicked="goToChat(profileData)"
                  :text="trans('BUTTON:GoToChat')"
                />
                <Button
                  v-show="!profileData.threadId"
                  variant="buttonRed"
                  invertIcon
                  @clicked="profileData.isBanned?
                    openAccountBlockedChatForbiddenModal(true)
                    :!userData.isSubscriptionActive || !userData.isVerified || profileData.isFake?
                      openModalFailedAction(true,'chat')
                      :openNewContactModal(true)"
                  :text="trans('BUTTON:SendInvitation')"
                />
              </div>
            </div>
          </div>
          <!-- Galeria -->
          <GalleryProfile
            v-show="picturesLoadingCounter <= 0"
            :userData="profileData" :isUserOwner="false"
            is-modal
            class="w-full flex flex-col items-start lg:flex-row"
          />
        </div>
      </div>
      <!-- Admin data - START -->
      <div v-if="!profileLoading && !gettingAnotherProfile && picturesLoadingCounter <= 0 && userData.isAdmin && profileAdministrationData">
        <hr class="-mx-3 lg:-mx-6 lg:mt-3 mb-4 border-dashed border-grayed" />
        <div class="font-semibold text-xl text-xl mb-2">
          {{ trans('LABEL:AdministrationData')  }}
        </div>
        <div class="flex flex-col gap-3">
          <!-- Email -->
          <div class="flex flex-row justify-between">
            <div class="text-s font-semibold">{{ trans('LABEL:Email') }}:</div><div class="text-base">{{ profileAdministrationData.email }}</div>
          </div>
          <!-- Subskrypcja -->
          <div v-if="profileAdministrationData.subscription.status">
            <div class="flex flex-row justify-between">
              <div class="text-s font-semibold">{{ trans('LABEL:Subscription') }}:</div>
              <div class="text-base" v-if="profileAdministrationData.subscription.status">
                {{
                  profileAdministrationData.subscription.validTo?
                      trans('LABEL:validTo')+' '+profileAdministrationData.subscription.validTo
                      :trans('LABEL:subscriptionStatus'+profileAdministrationData.subscription.status)
                }}
              </div>
            </div>
          </div>
	        <!-- Ostatnie logowanie -->
	        <div v-if="profileAdministrationData.lastLoginAt">
		        <div class="flex flex-row justify-between">
			        <div class="text-s font-semibold">{{ trans('LABEL:LastLogin') }}:</div>
			        <div class="text-base" v-if="profileAdministrationData.lastLoginAt">
				        {{ profileAdministrationData.lastLoginAt }}
			        </div>
		        </div>
	        </div>
          <!-- Blokada -->
          <div class="flex flex-col gap-2" v-if="profileAdministrationData.bannedAt">
            <div class="flex flex-row justify-between">
              <div class="text-s font-semibold">{{ trans('LABEL:Ban') }}</div>
              <div>
                <span class="text-base">{{profileAdministrationData.bannedAt}}</span><span class="text-grayed"> | </span><span>{{profileAdministrationData.bannedBy}}</span>
              </div>
            </div>
            <div class="flex flex-row justify-end pl-3" v-if="profileAdministrationData.bannedAt">
              <div class="text-s text-right">
                    <span class="leading-normal t" v-html="profileAdministrationData.banReason">
                    </span>
              </div>
            </div>
          </div>
          <!-- Usuniecie konta -->
          <div class="flex flex-col gap-2" v-if="profileAdministrationData.deletedAt">
            <div class="flex flex-row justify-between">
              <div class="text-s font-semibold">{{ trans('LABEL:Deletion') }}</div>
            </div>
            <div class="flex flex-row justify-between" v-if="profileAdministrationData.deletedAt">
              <div class="text-s font-semibold">{{ trans('LABEL:deletionRequested') }}</div>
              <div class="text-base">{{profileAdministrationData.deletedAt}}</div>
            </div>
            <div class="flex flex-row justify-between" v-if="profileAdministrationData.deletedAt && profileAdministrationData.removedAt">
              <div class="text-s font-semibold">{{ trans('LABEL:deletionCompleted') }}</div>
              <div class="text-base">
                <span>{{ profileAdministrationData.removedAt }}</span><span class="text-grayed"> | </span><span>{{ profileAdministrationData.removedBy }}</span>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col" v-if="profileAdministrationData.isAdmin && hasPermission(userData, Permissions.ADMINISTRATORS_MANAGE)">
            <div class="text-s font-semibold mb-2">{{ trans('LABEL:AdminRights') }}</div>
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(permission) in permissionsData">
                <label class="cursor-pointer" :for="'permission_' + permission">
                  <t-checkbox
                      :id="'permission_' + permission"
                      :name="'permission_' + permission"
                      :value="permission"
                      v-model="profileData.permissions"
                  />
                  {{ trans('PERMISSIONS:' + permission) }}
                </label>
              </div>
            </div>
            <div class="flex justify-end">
              <Button @clicked="setPermissions" :text="trans('BUTTON:Save')" />
            </div>
          </div>
        </div>
        <hr class="-mx-3 lg:-mx-6 mt-4 border-dashed border-grayed" />
      </div>
      <!-- Main Container -->
      <div v-if="!profileLoading && picturesLoadingCounter <= 0" class="w-full">
        <div class="flex w-full bg-white">
          <div class="w-full">
            <!-- Badge pod galeria -->
            <template v-if="profileData" class="flex flex-col justify-between">
              <div v-if="profileData.isFake" class="mb-2">
                <BadgeFrame>
                  <Badge tagName="span" variant="badgeSuccess">
                    {{ trans('LABEL:FakeProfile') }}
                  </Badge>
                </BadgeFrame>
              </div>
              <div v-if="profileData.isHidden  && !profileData.isBanned" class="mb-2">
                <BadgeFrame>
                  <Badge tagName="span" variant="badgeInfo">
                    {{ trans('LABEL:ProfileHidden') }}
                  </Badge>
                </BadgeFrame>
              </div>
              <div v-if="profileData.isBanned" class="mb-2">
                <BadgeFrame>
                  <Badge tagName="span" variant="badgeError">
                    {{ trans('LABEL:AccountBlocked') }}
                  </Badge>
                </BadgeFrame>
              </div>
              <!-- User name -->
              <div class="font-semibold text-xl leading-normal mb-2">
                <!-- Dla usunietego uzytkownika - tlumaczenie frazy "Profil usuniety" -->
                {{ profileData.removedAt ? trans(profileData.name) :profileData.name }}
              </div>
            </template>

            <!-- Gender, age, orientation, preference-->
            <div class="text-base leading-normal">
              <div class="flex flex-wrap flex-row justify-start px-1.5 lg:px-3 mb-2" >
                <template v-if="profileData.gender && profileData.gender.isMatchingId">
                  <span>{{ profileData.gender.desc }}</span>,&nbsp;
                </template>
                <template v-else>
                  <VTooltip>
                    <base-icon name="shuffle"/> <span>{{ profileData.gender.desc }}</span>,&nbsp;
                    <template #popper>
                      {{ trans('TOOLTIP:GenderDeclaredDescription') }}
                    </template>
                  </VTooltip>
                </template>
                <span>{{ profileData.age }}</span>,&nbsp;
                <span :class="{'text-badgeError': profileData.orientation.id === null}">{{ profileData.orientation.desc }}</span>,&nbsp;
                <span :class="{'text-badgeError': profileData.preference.id === null}">{{ profileData.preference.desc }}{{ profileData.isCoupleProfile ? ',&nbsp;' : '' }}</span>
                <span v-if="profileData.isCoupleProfile">{{ trans('LABEL:isCoupleProfile') }}</span>
              </div>
            </div>

            <!-- Appearance -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:Appearance') }}
            </div>

            <div class="flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3">
                <span :class="{'text-badgeError': profileData.height.value === null}">{{ profileData.height.desc }}</span>,
                <span :class="{'text-badgeError': profileData.weight.value === null}">{{ profileData.weight.desc }}</span>,
                <span :class="{'text-badgeError': profileData.shape.id === null}">{{ profileData.shape.desc }}</span>
              </div>
            </div>

            <!-- Status -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:Status') }}
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3">
                <span :class="{'text-badgeError': profileData.statusMarital.id === null}">{{ profileData.statusMarital.desc }}</span>,
                <span :class="{'text-badgeError': profileData.statusParental.id === null}">{{ profileData.statusParental.desc }}</span>
              </div>
            </div>

            <!-- Location -->
            <template v-if="profileData.location.desc && userData.location.id">
              <div class="inline-flex items-center gap-2 font-semibold text-s leading-normal mb-2">
                {{ trans('LABEL:Location') }}
              </div>
              <div class="px-1.5 lg:px-3 text-base  leading-normal mb-2">
                {{ profileData.location.desc }}
              </div>
            </template>

            <!-- SearchWho -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:LookingFor') }}
            </div>

            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <template v-if="profileData.searchWhos.desc">
                <div class="px-1.5 lg:px-3">
                  {{ profileData.searchWhos.desc }}
                </div>
              </template>
              <template v-else>
                <div class="px-1.5 lg:px-3 lg:px-6 text-grayed">
                  {{ trans('LABEL:lookingForNoYet') }}
                </div>
              </template>
            </div>


            <!-- SearchWhats -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:InterestedIn') }}
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <template v-if="profileData.searchWhats.desc">
                <div class="px-1.5 lg:px-3">
                  {{ profileData.searchWhats.desc }}
                </div>
              </template>
              <template v-else>
                <div class="px-1.5 lg:px-3 lg:px-6 text-grayed">
                  {{  trans('LABEL:interestedInNoYet') }}
                </div>
              </template>
            </div>

            <!-- Stimulants -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:Stimulants') }}
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3 gap-1 flex flex-col">
                <span v-if="profileData.stimulantsReg" class="" >{{ profileData.stimulantsReg}}</span>
                <span v-if="profileData.stimulantsOcc" class="" >{{ profileData.stimulantsOcc}}</span>
                <span v-if="profileData.stimulantsNev" class="" >{{ profileData.stimulantsNev}}</span>
                <span v-if="profileData.stimulantsNan" class="" >{{ profileData.stimulantsNan}}</span>
                <span v-if="profileData.stimulantsUnk" class=" text-badgeError" >{{ profileData.stimulantsUnk}}</span>
              </div>
            </div>

            <!-- Pets -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:ApproachPetting') }}
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3 whitespace-nowrap">
                <span class="whitespace-nowrap" :class="{'text-badgeError': profileData.approachPetting.id === null}">{{ profileData.approachPetting.desc }}</span>
              </div>
            </div>

            <!-- Description -->
            <div class="font-semibold text-s leading-normal mb-2 inline-flex items-center gap-2">
              {{ trans('LABEL:Description') }}
              <template v-if="profileData.isDescriptionSpicy">
                <VTooltip>
                  <base-icon class="w-4 h-4" name="fire" color="fill-badgeNeutral"/>
                  <template #popper>
                    {{ trans('LABEL:DescriptionSpicy') }}
                  </template>
                </VTooltip>
              </template>
            </div>
            <div class="px-1.5 lg:px-3 text-base  leading-normal description-text mb-2">
              <template v-if="profileData.description">
                <span v-html="profileData.description">
                </span>
              </template>
              <template v-else>
                {{ trans('LABEL:descriptionNoYet') }}
              </template>
            </div>

            <!-- Experience -->
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2">
              {{ trans('LABEL:Experience') }}
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start"
                 v-if="profileData.experiences"
            >
              <div class="flex flex-col flex-wrap gap-y-3">
                <template v-if="isAnyExperience">
                  <template
                      v-if="profileData.experiences['known'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                        <span
                            class="inline-flex items-center justify-start"
                            v-for="experience in profileData.experiences['known']"
                        >
                          <base-icon name="emojiHeart" class="h-5 w-5 mr-2"></base-icon>
                          {{ experience.name }}
                        </span>
                    </div>
                  </template>
                  <template v-if="profileData.experiences['known'].length > 0
                    && profileData.experiences['curious'].length > 0"
                  >
                    <hr class="mx-3 w-1/2"/>
                  </template>
                  <template
                      v-if="profileData.experiences['curious'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                        <span
                            class="inline-flex items-center justify-start"
                            v-for="experience in profileData.experiences['curious']"
                        >
                        <base-icon name="emojiWink" class="h-5 w-5 mr-2"></base-icon>
                          {{ experience.name }}
                        </span>
                    </div>
                  </template>
                  <template v-if="(profileData.experiences['known'].length > 0
                    || profileData.experiences['curious'].length > 0)
                    && profileData.experiences['noway'].length > 0"
                  >
                    <hr class="mx-3 w-1/2"/>
                  </template>
                  <template
                      v-if="profileData.experiences['noway'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                      <span
                        class="inline-flex items-center justify-start"
                        v-for="experience in profileData.experiences['noway']"
                      >
                        <base-icon name="emojiAngry" class="h-5 w-5 mr-2"></base-icon>
                        {{ experience.name }}
                      </span>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="px-1.5 lg:px-3 text-badgeError">
                    {{ trans('LABEL:experiencesNoYet') }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :showModal="accountBlockedRememberForbiddenModal || accountBlockedChatForbiddenModal"
      :header="trans('MODAL_TITLE:Attention')"
      @clicked="accountBlockedRememberForbiddenModal?openAccountBlockedRememberForbiddenModal(false)
        :accountBlockedChatForbiddenModal?openAccountBlockedChatForbiddenModal(false)
          :null"
    >
      <template v-slot:content>
        <span v-if="accountBlockedRememberForbiddenModal" class="lg:pl-3">
          {{ trans('MODAL_DESCRIPTION:AccountBlockedRememberForbidden') }}
        </span>
        <span v-if="accountBlockedChatForbiddenModal" class="lg:pl-3">
          {{ trans('MODAL_DESCRIPTION:AccountBlockedInviteForbidden') }}
        </span>
      </template>
      <template v-slot:footer>
        <Button
          variant="buttonRedOutlined"
          class="w-min"
          :text="trans('BUTTON:Close')"
          @clicked="accountBlockedRememberForbiddenModal?
            openAccountBlockedRememberForbiddenModal(false)
            :accountBlockedChatForbiddenModal?
              openAccountBlockedChatForbiddenModal(false)
              :null"
        />
      </template>
    </Modal>
    <Modal
      variant="basic"
      :loading="!shortMessageAlert ? messageSending : false"
      :buttonConfirmText="trans('BUTTON:SendMessage')"
      :disabled="!newMessage || newMessage.split(' ').length === 0"
      @onConfirm="newMessageAction"
      @clicked="openNewContactModal(false)"
      :showModal="newContactDialog"
      :header="trans('MODAL_TITLE:MakeContact')"
    >
      <template v-slot:content>
        <Hint variant="Info" class="mb-3">
          {{ trans('MODAL_DESCRIPTION:MakeContact') }}
        </Hint>
        <VueTrix
          v-model="newMessage" type="text" ref="messageInput" id="messageInput"
          :placeholder="trans('PLACEHOLDER:ChatForm')"
          inputName="msgContent"
          class="block outline-none trix-modal focus:text-gray-700 messages-chat"
          name="message" required autocomplete="off"
        />
      </template>
    </Modal>
    <Modal
      class = "lg:w-"
      :showModal="shortMessageDialog"
      :buttonConfirmText="trans('BUTTON:SendMessage')"
      @onConfirm="sendMessage"
      :loading="messageSending"
      @clicked="openShortMessageDialog(false)"
      :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        {{ trans('MODAL_DESCRIPTION:ShortNewMessage') }}
      </template>
    </Modal>

    <ModalFailedAction
      :context="failedActionContext"
      :show-modal="showModalFailedAction"
      :is-fake="profileData.isFake"
      @modal-clicked="openModalFailedAction"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Filters from "@/views/content/filters/Filters";
import router from "@/router";
import Modal from "@/utils/modal/Modal";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";

import GalleryProfile from "@/views/content/profile/components/gallery/GalleryShow";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Popover from "@/utils/popover/Popover";
import BaseIcon from "@/utils/icons/BaseIcon";
import ModalFailedAction from "@/views/content/components/ModalFailedAction.vue";
import Loader from "@/views/Loader.vue";
import {getWidth, getHeightOfScreenWidth, getHeightOfScreenHeight} from "@/common/helpers/utils";
import Hint from "@/views/content/components/Hint";
import Permissions from "@/common/enums/permissions";
import ProfilesCounter from "@/views/content/profile/components/ProfilesCounter.vue";
import ButtonSkip from "@/views/content/profile/components/ButtonSkip.vue";

export default {
  name: "ProfileGuest",
  components: {
    Badge,
    BadgeFrame,
    BaseIcon,
    Button,
    ModalFailedAction,
    Filters,
    GalleryProfile,
    Hint,
    Loader,
    Modal,
    PageContainer,
    PageTitle,
    Popover,
    ProfilesCounter,
    ButtonSkip,
  },
  props: {
    prevRoute: [Object, null],
	  username: [String],
	  profilesToShowMessage: {
			type: [String, null],
		  default: null,
	  },
	  profileSkipping: {
		  type: [Boolean],
		  default: null,
	  },
	  gettingAnotherProfile: {
		  type: [Boolean],
		  default: null,
	  },
  },
  data() {
    return {
      accountBlockedChatForbiddenModal:false,
      accountBlockedRememberForbiddenModal:false,
      getWidth: getWidth,
      getHeightOfScreenWidth:getHeightOfScreenWidth,
      getHeightOfScreenHeight:getHeightOfScreenHeight,
      failedActionContext:'',
      newContactDialog: false,
      newMessage: null,
      sendMessageButtonDisabled: false,
      shortMessageDialog: false,
      showAccountBannedModal: false,
      showModalFailedAction: false,
      showModalRememberUserFail: false,
	    adminPermissions: [],
    }
  },
  computed: {
	  Permissions() {
		  return Permissions
	  },
    ...mapGetters('profile', ['profileData', 'profileLoaded', 'profileLoading', 'sendRequestStatus', 'profileUpdated', 'profileUpdating', 'profileAdministrationData', 'profileRememberedLoading']),
    ...mapGetters('lang', ['langLocale']),
    ...mapGetters('chat', ['chosenUser', 'messageSending', 'messageSent']),
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('favourites', ['loadingAddFavUser', 'loadingRemoveFavUser']),
	  ...mapGetters('users', ['lastPage']),
	  ...mapGetters('utils', ['picturesLoadingCounter']),
	  ...mapGetters('permissions', ['permissionsData']),

    shortMessageAlert() {
      if(this.newMessage) {
        let strippedMessage = this.newMessage.replace(/(<([^>]+)>)/gi, "")
        return strippedMessage.length < 300;
      }
    },
    isMobile() {
      return checkIsMobileDevice();
    }
  },
  watch: {
    messageSent(newVal) {
      if(newVal) {
        this.newContactDialog = false;
        this.shortMessageDialog = false;
        this.sendMessageButtonDisabled = true;
      }
    },
	  username: {
			immediate: true,
		  handler(val) {
				if(val) {
					this.fetchProfileData({username: val, showLoader: true})
					this.fetchUserGallery(val);

					if(this.username.isAdmin) {
						this.fetchAdministrationDataRequest({username: val})
					}
        }
		  }
	  },
	  gettingAnotherProfile: {
			immediate: true,
			handler(val) {
				if (val) {
					this.setProfileData({})
				}
			}
	  }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus', 'setProfileData']),
    ...mapMutations('users', ['setBackFromNewContacts']),
    ...mapActions('profile', ['fetchProfileData', 'fetchAdministrationDataRequest']),
    ...mapActions('favourites', ['addFavouriteProfile', 'removeFavouriteProfile']),
    ...mapActions('chat', ['chooseMessageUserRequest', 'setCurrentThreadAction', 'clearCurrentThread', 'sendNewContactMessageRequest']),
    ...mapActions('gallery', ['fetchUserGallery']),
	  ...mapActions('permissions', ['sendPermissionsRequest']),
    ...mapActions('profile', ['removeRememberedUserRequest']),

    goToChat(profile) {
      router.push({name: 'chats', params: {id: profile.threadId}})
    },
    isAnyExperience() {
      return this.profileData.experiences['known'].length > 0 || this.profileData.experiences['curious'].length > 0
    },
    newMessageAction() {
      if(!this.shortMessageAlert) {
        this.sendMessage();
      } else {
        this.openShortMessageDialog(true);
      }
    },
    openAccountBlockedChatForbiddenModal(value) {
      this.accountBlockedChatForbiddenModal = value;
    },
    openAccountBlockedRememberForbiddenModal(value) {
      this.accountBlockedRememberForbiddenModal = value;
    },
    openModalFailedAction(value, context) {
      this.failedActionContext = context;
      this.showModalFailedAction = value;
    },
    openNewContactModal(value) {
      this.newContactDialog = value;
    },
    openShortMessageDialog(value) {
      this.shortMessageDialog = value;
    },
    rememberUserAction(username) {
			this.$emit('add-to-remembered', {username: username});
    },
    sendMessage() {
      this.sendNewContactMessageRequest({
        content: this.newMessage,
        receiverUsername: this.profileData.username,
        newContact: true,
      });
    },
	  setPermissions() {
			this.sendPermissionsRequest({
				permissions: this.profileData.permissions,
				username: this.profileData.username,
			});
	  },
	  skipProfile() {
			if (this.profileData.isFake) {
				this.openModalFailedAction(true, 'skip');
				return;
			}
			this.$emit('profile-skipped');
	  },

    forgetUserAction(username) {
      this.removeRememberedUserRequest({rememberedUsername: username, removeFromList: true})
    },
  },
  mounted() {
  },


}
</script>

<style lang="scss">

.trix-modal {
  border: none;
  padding: 0;

  .trix-content {
    height: 240px;
    max-height: 450px !important;
    overflow-y: auto !important;
    background: theme('colors.grayedLight') !important;
    border:none;
    border-radius: 6px;
    word-break: break-word;
  }
}


</style>
